@import '@kwara/components/src/foundations/_variables.scss';

.container {
  background: mobile-brand-col(mobile-dark-aqua);
  border: 0.03125rem solid mobile-brand-col(mobile-neutral-300);
  border-radius: 0.75rem;
  color: brand-col(white);
  width: 100%;
  min-width: 100%;

  & p {
    padding: 0;
    margin: 0;
  }

  & ul {
    padding: 0;
    margin: 0;
  }
}

.row-1,
.row-3 {
  padding: 1rem;
}

.row-1 {
  border-bottom: 0.03125rem solid mobile-brand-col(mobile-neutral-300);
  margin-bottom: 1rem;
}

.row-3 {
  border-top: 0.03125rem solid mobile-brand-col(mobile-neutral-300);
  display: flex;
  justify-content: center;
}

.join-sacco-btn {
  background: brand-col(white);
  color: mobile-brand-col(mobile-primary-500);
  width: 100%;
}

.sdc-accordion__header {
  color: brand-col(white);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.34);
  padding: 0.375rem 0.75rem;
  margin-bottom: 1rem;
}

.sdc-accordion__body {
  padding: 0 1.5rem;
}

.row-1__org-brand {
  display: flex;
  align-items: center;
  padding-bottom: 0.5rem;

  img {
    width: 40px;
    height: 40px;
    display: block;
  }

  p {
    padding-left: 0.5rem;
  }
}

@import '@kwara/components/src/foundations/_variables.scss';
@import '@kwara/components/src/foundations/_mixins.scss';

.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 32px;
  grid-row-gap: 32px;

  @include respond-to(viewport-7) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.item {
  &:nth-child(n + 3) {
    border-top: 1px solid brand-col('light-grey-400');
  }

  @include respond-to(viewport-7) {
    &:nth-child(n + 3) {
      border-top: none;
    }
    &:nth-child(n + 5) {
      border-top: 1px solid brand-col('light-grey-400');
    }
  }
}

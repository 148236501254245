@import '@kwara/components/src/foundations/_variables.scss';

.Main {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.Content {
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 1;
  background-image: url('confetti.gif');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}

.Information {
  width: 360px;
  height: 360px;
}

.Title {
  color: mobile-brand-col(mobile-primary-900);
}
.Subtitle {
  color: mobile-brand-col(mobile-primary-900);
}

.Image {
  width: 86px;
  height: 86px;
  border: 1px solid mobile-brand-col(mobile-neutral-300);
  box-sizing: border-box;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.07);
  border-radius: 12px;
}
.Footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  div {
    justify-content: center;
  }
}

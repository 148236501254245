@import '@kwara/components/src/foundations/_variables.scss';

.icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

@keyframes path-stroke {
  0% {
    stroke: #0a0a0a;
  }

  100% {
    stroke: brand-col(white);
  }
}

@keyframes circle-fill {
  0% {
    fill: #fec05a;
  }

  100% {
    fill: mobile-brand-col(mobile-primary-500);
  }
}

.icn-switch {
  & circle {
    animation: circle-fill 5s ease-in-out;
    fill: mobile-brand-col(mobile-primary-500);
  }

  & path {
    animation: path-stroke 5s ease-in-out;
    stroke: brand-col(white);
  }
}

@import '@kwara/components/src/foundations/_mixins.scss';

.amount {
  white-space: nowrap;
  text-align: right;
}

.tableTitles {
  display: none;
  @include respond-to(viewport-7) {
    display: block;
  }
}

.tableItem {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'a c'
    'b d';

  @include respond-to(viewport-7) {
    grid-template-areas: 'a b c d';
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.tableItem1 {
  grid-area: a;
}

.tableItem2 {
  grid-area: b;
  @include respond-to(viewport-7) {
    text-align: left;
  }
}

.tableItem3 {
  grid-area: c;
  text-align: right;
  @include respond-to(viewport-7) {
    text-align: left;
  }
}

.tableItem4 {
  grid-area: d;
  text-align: right;
}

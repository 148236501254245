@import '@kwara/components/src/foundations/_mixins.scss';

.Account {
  display: grid;
  grid-template-columns: 1fr 1fr 3px;
  grid-column-gap: 3px;

  @include respond-to(viewport-7) {
    grid-template-columns: 1fr 1fr 18px;
    grid-column-gap: 8px;
  }
}

.amount {
  white-space: nowrap;
  text-align: right;
}

.container {
  border: 0.5px solid #cbd5e1;
  border-radius: 12px;
}

.container_row1 {
  padding: 1rem;
}

.container_row2 {
  border-top: 0.5px solid #cbd5e1;
  padding: 0.5rem;
}

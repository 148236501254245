@import '@kwara/components/src/foundations/_variables.scss';
@import '@kwara/components/src/foundations/_mixins.scss';

.PrimarySection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 32px;
}

.Metrics > * {
  border-top: 1px solid brand-col(light-grey-400);
}

@import '@kwara/components/src/foundations/_mixins.scss';

.tableItem {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'a c'
    'b .';
}

.tableItem1 {
  grid-area: a;
}

.tableItem2 {
  grid-area: b;
}

.tableItem3 {
  grid-area: c;
  text-align: right;
}

.list {
  list-style: none;
  padding: 0;
  margin: 0;
  padding-top: 1rem;

  & li {
    padding: 0;
    margin: 0 auto;
    margin-bottom: 1.7rem;
    display: flex;
    justify-content: center;
  }
}

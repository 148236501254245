@import '@kwara/components/src/foundations/_variables.scss';

.DeclineButton {
  border-color: mobile-brand-col('mobile-error-500');
}

.ShowDetailsButton {
  background-color: white;
  color: mobile-brand-col('mobile-primary-500');
  border: 0.5px solid mobile-brand-col('mobile-neutral-300');
  min-width: 116px;
}

@import '@kwara/components/src/foundations/_variables.scss';
@import '@kwara/components/src/foundations/_mixins.scss';

.Card {
  background: rgb(9, 146, 80);
  background: linear-gradient(90deg, rgba(9, 146, 80, 1) 18%, rgba(8, 129, 137, 1) 100%);
  display: grid;
  grid-template-areas:
    'a b'
    'c d';
}

.GridAreaA {
  grid-area: a;
}

.GridAreaB {
  grid-area: b;
  text-align: right;
}

.GridAreaC {
  grid-area: c;
}

.Points {
  width: 36px;
  height: 36px;
  padding-top: 8px;
}

.GridAreaD {
  grid-area: d;
  text-align: right;
}

.Referral {
  height: 36px;
  padding-top: 8px;
}

.br12 {
  border-radius: 12px;
}

.CloseButton {
  background: white;
  padding: 12px;
  border-radius: 50%;
  filter: drop-shadow(0 12px 24px rgba(0, 0, 0, 0.07));
}

.InfoModalImg {
  height: 106px;
}

@keyframes memberships-show {
  0% {
    bottom: -100%;
    opacity: 0;
  }

  100% {
    bottom: 0;
    opacity: 1;
  }
}

.memberships {
  position: fixed;
  right: 0;
  left: 0;
  margin: 0 auto;
  animation: memberships-show 0.2s ease-in;
  bottom: 0;
}

@keyframes image-loading {
  0% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.container {
  width: 100%;
  padding: 0;
  margin: 0;

  & li {
    border-radius: 0.2rem;
    width: 100%;
    padding: 0;
    margin: 10px 0;
    display: flex;
    list-style: none;
  }
}

.img-wrapper {
  padding-right: 0.7rem;

  & img {
    animation: image-loading 1s linear infinite alternate;
    border-radius: 12px;
    width: 66px;
    height: 56px;
  }
}

@import '@kwara/components/src/foundations/_variables.scss';

.Container {
  position: relative;
  background-color: mobile-brand-col('mobile-neutral-100');
  border-radius: 12px;
  table {
    border-collapse: collapse;
  }
  .Header {
    color: mobile-brand-col('mobile-neutral-500');
  }
  .Body {
    tr {
      border-bottom: 0.5px solid mobile-brand-col('mobile-neutral-400');
      color: mobile-brand-col('mobile-neutral-900');
      .Action {
        color: mobile-brand-col('mobile-primary-500');
        text-decoration: underline;
      }
    }
  }
  .Footer {
    height: 52px;
  }
}

.Container::before {
  content: ' ';
  display: flex;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 52px;
  background-color: mobile-brand-col('mobile-neutral-900');
  opacity: 0.04;
}

.Container.Open::before {
  border-radius: 0 0 12px 12px;
}

.Container.Close::before {
  border-radius: 12px;
}

@import '@kwara/components/src/foundations/_variables.scss';

.Container {
  border: 0.5px solid mobile-brand-col('mobile-neutral-300');
  background-color: white;
  border-radius: 12px;
  margin: 12px 0;

  .Header {
    .Subtitle {
      color: mobile-brand-col('mobile-neutral-500');
    }
  }
  .Content {
    .AmountLabel {
      color: mobile-brand-col('mobile-neutral-400');
    }
    .ShowDetailsButton {
      width: 153px;
      background-color: white;
      color: mobile-brand-col('mobile-primary-500');
      border: 0.5px solid mobile-brand-col('mobile-neutral-300');
    }
  }
  .DetailsContainer {
    border-top: 0.5px solid mobile-brand-col('mobile-neutral-300');
    &.Close {
      display: none;
    }
    &.Open {
      display: block;
    }
  }

  .Footer {
    border-top: 0.5px solid mobile-brand-col('mobile-neutral-300');
  }
}

@import '@kwara/components/src/foundations/_variables.scss';

.Container {
  position: relative;
  background-color: mobile-brand-col('mobile-neutral-200');
  border-radius: 12px;

  .Content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
    .Header {
      text-align: center;
      width: 100%;

      .Title {
        text-align: center;
        margin: 10px 0;
        .Line {
          flex-grow: 1;
          height: 1px;
          background-color: mobile-brand-col('mobile-neutral-400');
        }
      }
      .Subtitle {
        max-width: 250px;
      }
    }

    .Body {
      width: 100%;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      padding: 0px 2px 6px;
    }

    .Footer {
      width: 100%;
      height: 48px;

      .Remove {
        border: 0.5px solid mobile-brand-col('mobile-error-600');
      }
      button {
        span {
          margin: 5px;
        }
      }
    }
  }
}

.Container::before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url('../../assets/background.png');
  opacity: 0.1;
  background-repeat: no-repeat;
  background-position: right bottom;
  border-radius: 12px;
}

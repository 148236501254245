.Layout {
  margin: 0 -16px;
}

.Image {
  width: 50px;
  height: 50px;
  border: 1px solid mobile-brand-col(mobile-neutral-300);
  box-sizing: border-box;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.07);
  border-radius: 12px;
}

.swiper-container {
  padding-bottom: 30px;
}

.swiper-pagination {
  bottom: 0;
}

.swiper-pagination-bullet {
  width: 6px;
  height: 6px;
  background: transparent;
  border: 1px solid #aaf0c4;
  opacity: 1;
}

.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 1px;
}

.swiper-pagination-bullet-active {
  width: 20px;
  height: 6px;
  transition: width 0.5s;
  border-radius: 5px;
  background: #099250;
  border: 1px solid transparent;
}

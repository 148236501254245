@import '@kwara/components/src/foundations/_variables.scss';

.container-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  background: mobile-brand-col(mobile-white);
  border-radius: 16px;
  color: mobile-brand-col(mobile-neutral-900);
  text-align: center;
  width: 90vw;
  height: 36.375rem;
  max-height: 95vh;
  margin: 0 auto;
  padding: 1rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  overflow-y: scroll;

  & img {
    width: 100%;
    max-width: 350px;
  }
}

.close-btn-wrapper {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 1.5rem;

  & button {
    background-color: transparent;
  }
}

.close-btn {
  border: 0;
  border: none;
  border-radius: 50%;
  width: 2.25rem;
  height: 2.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.cta-btn {
  background-color: mobile-brand-col(mobile-primary-500);
  border-radius: 8px;
  color: mobile-brand-col(mobile-white);
  width: 100%;
  margin-top: 1rem;
  width: 200px;
}

.list {
  list-style: none;
  padding: 0;
  margin: 0;
  padding-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: column;
  grid-template-areas:
    'a b'
    'c d'
    'e f';

  & li {
    padding: 0;
    margin: 0 auto;
    margin-bottom: 1.7rem;
    display: flex;
    justify-content: center;
  }
}

.a {
  grid-area: a;
  width: 100%;
}

.b {
  grid-area: b;
  width: 100%;
}

.c {
  grid-area: c;
  width: 100%;
}

.d {
  grid-area: d;
  width: 100%;
}

.e {
  grid-area: e;
  width: 100%;
}

.f {
  grid-area: f;
  width: 100%;
}

@import '@kwara/components/src/foundations/_variables.scss';

.container {
  background: brand-col(white);
  box-shadow: 0 0 24px 5px hsl(0, 1%, 70%);
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  width: 100%;
  height: 391px;
  padding: 1rem;
  margin: 0;

  & ul {
    width: 100%;
    padding: 0;
    margin: 0;

    /**
     * <Item/> styles
     */
    & li {
      width: 100%;
      padding: 0;
      margin: 0;
      margin-bottom: 20px;
      display: flex;
      align-items: flex-start;
      list-style: none;
      cursor: pointer;

      & p {
        margin: 0;
        padding: 0;
        padding-left: 0.7rem;
        line-height: 150%;

        &:nth-child(2) {
          color: brand-col(neutral-500);
        }
      }

      & img {
        border-radius: 12px;
        width: 60px;
        height: 60px;
        border: 1px solid brand-col(neutral-300);
      }
    }
  }
}

.container-row1 {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 0.5rem;

  h2 {
    color: brand-col(primary-500);
  }

  & button {
    padding: 0;
    margin: 0;
    background-color: transparent;
    border: 0;
    cursor: pointer;
  }
}

.disabled-list {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.disabled-item {
  pointer-events: none;
  opacity: 0.5;
}

@import '@kwara/components/src/foundations/_variables.scss';

.Container {
  height: 117px;
  width: 100%;
  background-color: mobile-brand-col('mobile-neutral-100');
  background-image: url(./background.png);
  background-repeat: no-repeat;
  background-position: right bottom;
  border-radius: 16px;
  border: 0.5px solid mobile-brand-col('mobile-neutral-300');
}

.Blur {
  color: transparent;
  text-shadow: #111 0 0 15px;
  transition: 0.4s;
}

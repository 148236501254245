@import '@kwara/components/src/foundations/_variables.scss';

.container {
  transition: background-color 300ms ease-out;
  height: 100%;
}

.container.contact-modal-open {
  height: 100vh;
  overflow: hidden;
  background-color: brand-col(mobile-black);
  transition: background-color 300ms ease-out;
}

.app-layout {
  height: 100%;
  transform-origin: center top;
  transition: transform 300ms ease-out, border-radius 300ms ease-out, opacity 300ms ease-out;
}

.contact-modal-open .app-layout {
  transform: perspective(50px) scale(0.95) translateY(23px);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  opacity: 0.65;
}

@import '@kwara/components/src/foundations/_mixins.scss';

.yearSummary {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'year .'
    'principal outstanding'
    'interest balance';

  grid-column-gap: 10px;
  grid-row-gap: 5px;
}

.year {
  grid-area: year;
}

.principal {
  grid-area: principal;
  text-align: left;
}

.outstanding {
  grid-area: outstanding;
  text-align: right;
}

.interest {
  grid-area: interest;
  text-align: left;
}

.balance {
  grid-area: balance;
  text-align: right;
}

.gridText {
  white-space: nowrap;
  @include respond-to(viewport-7) {
    display: inline-block;
    white-space: pre-wrap;
  }
}

@import '@kwara/components/src/foundations/_mixins.scss';
@import '@kwara/components/src/foundations/_variables.scss';

.Section {
  padding: 12px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'a c'
    'b b';
}

.Content {
  background-color: mobile-brand-col('mobile-primary-50');
  border-radius: 5px;
  padding: 16px;
  grid-area: b;
  text-align: left;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}

.Heading {
  grid-area: a;
  min-width: 144px;
  text-align: left;
}

.EditColumn {
  grid-area: c;
  text-align: right;
}

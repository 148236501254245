@import '@kwara/components/src/foundations/_variables.scss';

.Tag {
  padding: 0 16px;
  border-radius: 0 12px 0 4px;
  &.Critical {
    background-color: mobile-brand-col('mobile-error-50');
  }
  &.Success {
    background-color: mobile-brand-col('mobile-success-50');
  }
  &.Primary {
    background-color: mobile-brand-col('mobile-primary-200');
  }
  &.Default {
    background-color: mobile-brand-col('mobile-neutral-200');
  }
  &.Pending {
    background-color: mobile-brand-col('mobile-info-200');
  }
}

@import '@kwara/components/src/foundations/_mixins.scss';

.container {
  width: 100%;
  display: grid;
  grid-template-areas: 'a b';
  grid-template-columns: 1fr 1fr;
  @include respond_to(viewport-7) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'a'
      'b';
  }
}

.title {
  grid-area: a;
}

.value {
  grid-area: b;
}

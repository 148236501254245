@import '@kwara/components/src/foundations/_variables.scss';

.Container {
  position: relative;
  background-color: mobile-brand-col('mobile-neutral-200');
  border-radius: 12px;

  .Content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
    .Header {
      text-align: center;
      width: 100%;
      z-index: 1;

      .Title {
        text-align: center;
        margin: 10px 0;
        .Line {
          flex-grow: 1;
          height: 1px;
          background-color: mobile-brand-col('mobile-neutral-400');
        }
      }
      .Subtitle {
        max-width: 250px;
      }
    }

    .Body {
      width: 100%;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      padding: 0px 2px 6px;
      z-index: 1;
      .Icon {
        border-radius: 16px;
      }
    }

    .Footer {
      background: white;
      border-radius: 12px;
      width: 100%;
      height: 48px;
      z-index: 1;
    }
  }

  &.Open {
    height: 163px;
    .Body {
      display: flex;
    }
  }
  &.Close {
    .Body {
      display: none;
    }
    .Footer {
      display: none;
    }
    &.Dirty {
      height: 112px;
      .Footer {
        display: block;
        background: mobile-brand-col('mobile-neutral-100');
      }
    }
    height: 64px;
  }

  &.Dirty {
    background-color: mobile-brand-col('mobile-primary-400');
    .Title {
      color: mobile-brand-col('mobile-neutral-100');
      .Line {
        background-color: mobile-brand-col('mobile-neutral-200');
      }
    }

    .Subtitle {
      color: mobile-brand-col('mobile-neutral-100');
    }
  }
}

.Container::before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url('../../assets/background.png');
  opacity: 0.1;
  background-repeat: no-repeat;
  background-position: right bottom;
  border-radius: 12px;
}

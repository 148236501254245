@import '@kwara/components/src/foundations/_mixins.scss';
@import '@kwara/components/src/foundations/_variables.scss';

.Background {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./background-member.png');
  background-size: cover;
  background-position: center;
  position: absolute;
}

.Container {
  display: grid;
  grid-template-rows: 30% 70%;
  height: 100vh;
}

.Ilustration {
  position: absolute;
  top: 87px;
  z-index: 10;
  height: 126px;
  width: 180px;
}

.FormWrap {
  border-radius: 30px 30px 0 0;
  position: absolute;
  top: 150px;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 1rem;
}

.Form {
  width: 100%;
  max-width: 320px;
}

.Header {
  text-align: center;
}
.Footer {
  text-align: center;
}

@import '@kwara/components/src/foundations/_variables.scss';

.picker {
  background-color: transparent;
  border-radius: 100px;
  border: 1px solid;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0.8rem 1.5rem;
  min-width: 120px;

  & img {
    width: 20px;
    height: 20px;
    padding-right: 0.5rem;
  }
}

.picker--inactive {
  border-color: mobile-brand-col(mobile-info-600);
  color: mobile-brand-col(mobile-info-600);
}

.picker--active {
  background: mobile-brand-col(mobile-success-50);
  border-color: mobile-brand-col(mobile-success-600);
  color: mobile-brand-col(mobile-success-500);
}

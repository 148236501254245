.financial-products {
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: column;
  grid-template-areas:
    'savings savings'
    'investment investment'
    'loans insurance'
    'others others';
  gap: 1rem;
  padding: 0;
  margin: 0;
  padding-top: 1rem;

  & li {
    padding: 0;
    margin: 0 auto;
    margin-bottom: 0.7rem;
    display: flex;
    justify-content: center;
  }
}

.savings {
  grid-area: savings;
  width: 100%;
}

.investment {
  grid-area: investment;
  width: 100%;
}

.loans {
  grid-area: loans;
  width: 100%;
}

.insurance {
  grid-area: insurance;
  width: 100%;
}

.others {
  grid-area: others;
  width: 100%;
}

@import '@kwara/components/src/foundations/_variables.scss';

.container {
  background: linear-gradient(270deg, #d97276 0%, #7041c3 100%);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  color: brand-col(white);

  & img {
    width: 100%;
    max-width: 13.625rem;
    height: 13.625rem;
    margin: 0 auto;
  }
}

.loader-wrapper {
  background: mobile-brand-col(mobile-neutral-100);
  border-radius: 0.3125rem;
  height: 1.125rem;
  width: 100%;

  & p {
    font-size: 0.625rem;
    text-align: start;
    margin: 0;
    padding: 0;
    padding-top: 0.3rem;
  }
}

.loader {
  background-color: mobile-brand-col(mobile-primary-500);
  border-radius: 0.3125rem;
  height: 1.125rem;
  width: 30%;
}

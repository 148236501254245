.container {
  display: flex;
  align-items: baseline;

  & img {
    width: 0.625rem;
    height: 0.625rem;
    padding-right: 0.5rem;
  }
}

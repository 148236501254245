@import '@kwara/components/src/foundations/_variables.scss';

.container {
  background: mobile-brand-col(mobile-white);
  border: 1px solid mobile-brand-col(mobile-neutral-300);
  padding-bottom: 0;
  padding-right: 0;
  display: grid;
  grid-template-rows: 50% 50%;
}

.container__row1 {
  & h3 {
    padding: 0;
    padding-right: 1rem;
    margin: 0;
  }

  & p {
    padding: 0;
    padding-right: 1rem;
    margin: 0;
  }
}

.container__row2 {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & img {
    border-bottom-right-radius: 1rem;
    width: 40%;
    height: 100%;
  }
}

.buy-btn {
  border: 0;
  border: none;
  border-radius: 8px;
  background: mobile-brand-col(mobile-primary-500);
  color: mobile-brand-col(mobile-white);
}

@import '@kwara/components/src/foundations/_variables.scss';

.container {
  background: mobile-brand-col(white);
  display: grid;
  grid-template-rows: 40% 60%;
  height: 100%;
}

.overlay {
  background-color: rgb(9, 144, 79, 0.9);
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
}

.header {
  background-image: url('./assets/iconBg.png');
  background-size: cover;
  background-position: center;
  position: relative;
  height: 100%;
  width: 100%;

  h1 {
    color: mobile-brand-col(mobile-info-400);
    line-height: 150%;
    padding: 0;
    margin: 0;
    width: 200px;
  }

  p {
    color: brand-col(white);
    line-height: 150%;
    padding: 0;
    margin: 0;
  }
}

.body {
  background: brand-col(white);
  border-radius: 30px 30px 0px 0px;
  padding: 3rem 1rem;
  position: absolute;
  top: 35%;
  height: calc(100% - 35%);
  width: 100%;

  & footer {
    margin-top: 4rem;
    text-align: center;
  }
}

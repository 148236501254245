@import '@kwara/components/src/foundations/_variables.scss';

.container {
  background: linear-gradient(270deg, #d97276 0%, #7041c3 100%);
  color: brand-col(white);
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 10% 90%;

  & header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > h1 {
      line-height: 125%;
    }
  }

  & footer {
    text-align: center;
  }
}

.body {
  display: grid;
  grid-template-rows: 40% 50% 10%;

  img {
    display: block;
    margin: 0 auto;
    padding-top: 3rem;
  }
}

.continue,
.pick {
  padding: 1.5rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 1rem 0;
}

.continue {
  background: brand-col(neutral-900);
  color: brand-col(white);
  box-shadow: 0px 10px 20px rgba(19, 19, 19, 0.25);
  margin-top: 2rem;
}

.pick {
  border: 0.5px solid brand-col(neutral-200);
  color: brand-col(neutral-200);
}
